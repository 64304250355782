import moment from 'moment';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

import AntdTable from 'antd/lib/table';
import AntdButton from 'antd/lib/button';
import {
  CopyrightOutlined,
} from '@ant-design/icons';

import Types from '../../common/modules/types';

import tests from '../../helpers/tests';

export const fieldLogo = ['FieldUploadRefs', {
  name: 'logo',
  maxCount: 1,
  col: 1,
  accept: 'image/jpg,image/jpeg,image/png,image/gif',
  transformations: [[
    'GM',
    { command: 'compress', quality: 95, maxWidth: 1200 },
  ]],
}];

export const fieldName = ['FieldText', {
  name: 'name',
  rules: [['validation.isRequired']],
}];

export const fieldCountry = ['FieldSelect', {
  name: 'country',
  placeholder: 'Country',
  showArrow: true,
  showSearch: true,
  optionFilterProp: 'children',
  // disabled: [tests.isNotCreateActivity],
  // virtal: [tests.isNotCreateActivity],
  disabled: [tests.viewerIsNotAdminNorContentManager],
  virtual: [tests.viewerIsNotAdminNorContentManager],
  initialValue: null,
  choices: [
    { label: 'N/A', value: null },
    ...Types.COUNTRIES.map(({ name, iso3a2 }) => ({
      label: name,
      value: iso3a2,
    })),
  ],
}];

export const fieldVisualIdPrefix = ['FieldText', {
  label: 'ID Prefix',
  name: 'visualIdPrefix',
  placeholder: '3 Letter Visual ID Prefix (leave empty to auto-generate)',
  hidden: [tests.viewerIsNotAdminNorContentManager],
  virtual: [tests.viewerIsNotAdminNorContentManager],
}];

const Table = styled(AntdTable)`
  .ant-table-body {
    margin: 0px !important;
  }
`;

const TableValueWrapper = styled.span`
  ${({ $disabled }) => $disabled && css`
    opacity: 0.4;
  `}
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.less.textColor};
`;

export const CreditIcon = styled(CopyrightOutlined)`
  transform: scale(0.8);
  transform-origin: center;
`;

const CreditsTable = ({ credits = [], balance = 0 }) => {
  const {
    creditsFinal,
    creditsAdded,
  } = useMemo(
    // () => (credits || []).map((credit) => ({
    //   ...credit,
    //   expiresAtTS: (
    //     credit.expiresAt ? new Date(credit.expiresAt).getTime() : Infinity
    //   ),
    //   createdAtTS: (
    //     new Date(credit.createdAt).getTime()
    //   ),
    // })),
    () => ({
      creditsFinal: credits || [],
      creditsAdded: (credits || []).reduce(
        (agr, { initialAmount }) => (agr + initialAmount),
        0,
      ),
    }),
    [credits],
  );
  return (
    <Table
      title={null}
      pagination={false}
      rowKey="_id"
      bordered
      size="small"
      dataSource={creditsFinal}
      columns={[{
        key: 'createdAt',
        // dataIndex: 'createdAt',
        title: 'Created At',
        sortDirections: ['descend', 'ascend'],
        defaultSortOrder: 'descend',
        sorter: (a, b) => (
            a.createdAt > b.createdAt ? 1
          : a.createdAt < b.createdAt ? -1
          : 0
        ),
        render: record => (
          <TableValueWrapper $disabled={!record.valid}>
            {moment(record.createdAt).format('lll')}
          </TableValueWrapper>
        ),
      }, {
        key: 'expiresAt',
        // dataIndex: 'expiresAt',
        title: 'Expires At',
        sortDirections: ['descend', 'ascend'],
        sorter: (a, b) => (
            (!a.expiresAt && !b.expiresAt)
          ? (
                a.createdAt > b.createdAt ? 1
              : a.createdAt < b.createdAt ? -1
              : 0
            )
          : !a.expiresAt ? 1
          : !b.expiresAt ? -1
          : a.expiresAt > b.expiresAt ? 1
          : a.expiresAt < b.expiresAt ? -1
          : 0
        ),
        render: record => (
          <TableValueWrapper $disabled={!record.valid}>
            {
                record.expiresAt
              ? moment(record.expiresAt).format('ll')
              : 'Never'
            }
          </TableValueWrapper>
        ),
      }, {
        key: 'amount',
        // dataIndex: 'amount',
        title: 'Available',
        sorter: (a, b) => (
            a.amount > b.amount ? 1
          : a.amount < b.amount ? -1
          : 0
        ),
        align: 'right',
        render: record => (
          <TableValueWrapper $disabled={!record.valid}>
            {record.amount}
            &nbsp;
            <CreditIcon />
          </TableValueWrapper>
        ),
      }, {
        key: 'initialAmount',
        title: 'Added',
        sorter: (a, b) => (
            a.initialAmount > b.initialAmount ? 1
          : a.initialAmount < b.initialAmount ? -1
          : 0
        ),
        align: 'right',
        render: record => (
          <TableValueWrapper $disabled={!record.valid}>
            {record.initialAmount}
            &nbsp;
            <CreditIcon />
          </TableValueWrapper>
        ),
      }]}
      footer={() => (
        <FooterWrapper>
          <span>
            Total:
          </span>
          <span>
            {`${balance || 0} / ${creditsAdded || 0}`}
            &nbsp;
            <CreditIcon />
          </span>
        </FooterWrapper>
      )}
    />
  );
};

export const fieldCredits = ['FieldReact', {
  name: 'credits',
  label: 'Credits',
  skip: [tests.isNotEditActivity],
  virtual: [() => true],
  // eslint-disable-next-line arrow-body-style
  renderContent: props => (
    <CreditsTable
      credits={props.data?.credits}
      balance={props.data?.creditBalance || 0}
    />
  ),
}];

export const fieldPdfReportIncludeCaseStudy = ['FieldRadio', {
  name: 'pdfReportIncludeCaseStudy',
  label: 'Include Case Study in PDF Report',
  block: true,
  choices: [
    { label: 'Include', value: true },
    { label: 'Exclude', value: false },
  ],
  initialValue: true,
}];

export const fieldDashboardAccess = ['FieldRadio', {
  name: 'dashboardAccess',
  label: 'In-Store Insights Access',
  skip: [tests.viewerIsNotAdmin],
  block: true,
  choices: [
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false },
  ],
  initialValue: false,
}];

export const fieldDashboardPowerBiReports = ['FieldSortableList', {
  name: 'dashboardPowerBiReports',
  label: 'In-Store Insights Reports',
  skip: [tests.viewerIsNotAdmin],
  hidden: [
    [
      'condition.testFieldValue',
      'dashboardAccess',
      '!==',
      true,
    ],
  ],
  head: 'horizontal',
  condensed: true,
  fields: [
    ['FieldText', {
      name: 'label',
      label: null,
      prefix: 'Label:',
      rules: [['validation.isRequired']],
      col: 8,
    }],
    ['FieldText', {
      name: 'url',
      label: null,
      prefix: 'Report URL:',
      rules: [['validation.isRequired']],
      col: 16,
    }],
    ['FieldReact', {
      name: 'embedAdminOpen',
      label: null,
      skip: [
        props => !props.data?.embedParams,
      ],
      renderContent: (props) => (
        <AntdButton
          href={`/in-store-insights?report=${
            props.parentData?._id
          }-${
            props.data._id
          }`}
          block
          // onClick={() => {
          //   props.history.push(`/in-store-insights?report=${
          //     props.parentData?._id
          //   }-${
          //     props.data._id
          //   }`);
          // }}
        >
          Preview
        </AntdButton>
      ),
    }],
  ],
}];

export const fieldTrackerTitle = ['FieldTitle', {
  title: 'In-Store Gallery',
  subtitle: 'Setup organization permissions',
  skip: [tests.viewerIsNotAdmin],
}];

export const fieldTrackerMode = ['FieldRadio', {
  name: 'tracker.mode',
  label: 'Mode',
  choices: Types.TRACKER_MODE_LIST.map(({ id: value, label }) => ({
    label,
    value,
  })),
  block: true,
  initialValue: 'TRENDING',
  skip: [tests.viewerIsNotAdmin],
}];

export const fieldTrackerAdditinalCategories = ['FieldConnectionSelect', {
  name: 'tracker.additionalCategories',
  label: 'Additional Categories',
  url: '/categories',
  placeholder: 'Select additional categories',
  mode: 'multiple',
  allowClear: true,
  searchPaths: ['name'],
  skip: [
    tests.skipIfViewerNotAdminNorCustomerWhichCanManageOrganization,
    ['condition.and', [
      tests.viewerIsNotAdmin,
      props => !props.data?.tracker?.additionalCategories?.length,
    ]],
  ],
  disabled: [tests.viewerIsNotAdmin],
  virtual: [tests.viewerIsNotAdmin],
}];

export const ALL = [
  fieldLogo,
  fieldName,
  fieldCountry,
  fieldVisualIdPrefix,
  fieldCredits,
  fieldPdfReportIncludeCaseStudy,
  fieldDashboardAccess,
  fieldDashboardPowerBiReports,
  fieldTrackerTitle,
  fieldTrackerMode,
  fieldTrackerAdditinalCategories,
];
